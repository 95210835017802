
import { defineComponent, onMounted, reactive, Ref, ref } from "vue";
import InputText from "@/components/primevueCustom/InputText.vue";
import { IAlert, IResponseData, ResultDatas, SEX } from "@/models/common";
import InputMask from "primevue/inputmask";
import Checkbox from "primevue/checkbox";
import $API from "@/services";
import { AxiosResponse } from "axios";
import { useStore } from "@/store";
import { CommonMutationTypes } from "@/store/modules/common/mutations";
import { IMyProfile } from "@/models/account";
import Button from "primevue/button";
import { Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { AuthActionTypes } from "@/store/modules/auth/actions";
import { messageType, IMessage } from "@/models/message";
import SelectButton from "primevue/selectbutton";
import Withdrawal from "@/components/mypage/Withdrawal.vue";
import RRNDialog from "@/components/mypage/SetResidentRegistrationNumber.vue";

export default defineComponent({
  name: "User Info",
  props: {
    isRRN: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const { t } = useI18n({ useScope: "global" });
    const store = useStore();
    const state = reactive({
      profile: {} as IMyProfile,
      message: {} as IMessage,
      isLoading: true,
      isRRN: props.isRRN,
    });
    const showRRNDialog = ref(false);

    const alert: Ref<IAlert> = ref({
      type: messageType.ERROR,
      content: "",
    });

    const getUserInfo = async () => {
      store.commit(CommonMutationTypes.SET_IS_LODING, true);

      try {
        const profile: IMyProfile | null =
          await $API.AccountsService.getMyProfile();

        if (!profile) return;

        const { PhoneNumber } = profile;
        if (!PhoneNumber && state.isRRN) {
          alert.value = {
            content:
              "대면진료 예약을 위한 주민등록번호와 휴대폰 번호를 입력하세요.",
            type: messageType.ERROR,
          };
        } else if (!PhoneNumber) {
          alert.value = {
            content: "서비스 이용을 위해 휴대폰 번호를 입력하세요.",
            type: messageType.ERROR,
          };
        } else if (state.isRRN) {
          alert.value = {
            content: "대면진료 예약을 위한 주민등록번호를 입력하세요.",
            type: messageType.ERROR,
          };
        }
        state.profile = profile;
      } catch (e) {
        console.error(e);
      } finally {
        store.commit(CommonMutationTypes.SET_IS_LODING, false);
      }
    };

    const showWithdrawal = ref(false);

    const sexOptions = ref([SEX.M, SEX.F]);

    const selectSex = (sex: SEX) => {
      state.profile.Sex = sex;
    };

    const updateRRN = async () => {
      showRRNDialog.value = false;
      await getUserInfo();
    };

    onMounted(async () => {
      state.isLoading = true;
      await getUserInfo();
      state.isLoading = false;
    });

    return {
      state,
      SEX,
      getUserInfo,
      t,
      store,
      alert,
      sexOptions,
      showWithdrawal,
      selectSex,
      showRRNDialog,
      updateRRN,
    };
  },

  methods: {
    async onSave() {
      const res: AxiosResponse<IResponseData<string>> =
        await $API.AccountsService.saveMyProfileData(this.state.profile);
      const { data } = res;
      if (data.ResultData === ResultDatas.SUCCESS) {
        await this.getUserInfo();
        this.store.dispatch(AuthActionTypes.GET_ACCOUNT_INFO);

        this.alert = {
          title: this.t("Saved"),
          content: this.t("Saved"),
          type: messageType.INFO,
        };
      }
    },
    updatePharmacyKey(key: number | null) {
      this.state.profile.PharmacyKey = key ?? 0;
    },
  },

  components: {
    InputText,
    InputMask,
    Checkbox,
    Button,
    Field,
    Form,
    SelectButton,
    Withdrawal,
    RRNDialog,
  },
});
