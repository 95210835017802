
import { defineComponent, onMounted, reactive, Ref, ref } from "vue";
import { useI18n } from "vue-i18n";
import InputText from "@/components/primevueCustom/InputText.vue";
import Button from "primevue/button";
import { Form, Field } from "vee-validate";
import { IAlert, ResultDatas } from "@/models/common";
import { useStore } from "vuex";
// import Message from "primevue/message";
import SelectButton from "primevue/selectbutton";
import { messageType } from "@/models/message";
import $API from "@/services";

interface IRRN {
  rrn1: Number | null;
  rrn2: Number | null;
  compare: Array<Number>;
}

export default defineComponent({
  name: "Set RRN",
  emits: ["close", "updateRRN"],
  setup(props, { emit }) {
    const { t } = useI18n({ useScope: "global" });
    const rrn: IRRN = reactive({
      rrn1: null,
      rrn2: null,
      compare: [2, 3, 4, 5, 6, 7, 8, 9, 2, 3, 4, 5],
    });
    // const error = ref("");
    const store = useStore();

    const alert: Ref<IAlert> = ref({
      type: messageType.ERROR,
      content: "",
    });

    const agree: Ref<"Y" | "N" | null> = ref(null);

    const validate = () => {
      let sum = 0;
      const strRRN = [
        ...(rrn.rrn1?.toString() ?? ""),
        ...(rrn.rrn2?.toString() ?? ""),
      ];
      strRRN.forEach((x, index) => {
        if (index < rrn.compare.length) {
          sum += (Number.parseInt(x) ?? 0) * rrn.compare[index].valueOf();
        }
      });
      const validValue = (11 - (sum % 11)) % 10;
      if (validValue.toString() === strRRN[strRRN.length - 1]) {
        return true;
      }
      return false;
    };

    const scrolling = () => {
      const body$ = document.querySelector("body");
      if (body$?.classList.contains("p-overflow-hidden"))
        body$?.classList.remove("p-overflow-hidden");
    };

    const save = async () => {
      if (validate()) {
        const number =
          (rrn.rrn1 ? rrn.rrn1.toString() : "") +
          (rrn.rrn2 ? rrn.rrn2.toString() : "");

        const res = await $API.AccountsService.setResidentRegNumber(number);
        const {
          data: { ResultData },
        } = res;
        if (ResultData === ResultDatas.SUCCESS) update();
      }
    };

    const update = () => {
      scrolling();
      emit("updateRRN");
    };
    const close = () => {
      scrolling();
      emit("close");
    };

    onMounted(() => {
      const body$ = document.querySelector("body");
      if (!body$?.classList.contains("p-overflow-hidden"))
        body$?.classList.add("p-overflow-hidden");
    });

    return {
      t,
      rrn,
      store,
      alert,
      save,
      close,
      agree,
    };
  },
  components: {
    InputText,
    Button,
    Form,
    Field,
    SelectButton,
  },
});
