
import { defineComponent, onMounted, Ref, ref } from "vue";
import { useI18n } from "vue-i18n";
import InputText from "@/components/primevueCustom/InputText.vue";
import Button from "primevue/button";
import { Form, Field } from "vee-validate";
import {
  ExceptionMessages,
  IAlert,
  IResponseData,
  ResultDatas,
} from "@/models/common";
import { useStore } from "vuex";
// import Message from "primevue/message";

import { messageType } from "@/models/message";
import { AxiosResponse } from "axios";
import $API from "@/services";
import { AuthActionTypes } from "@/store/modules/auth/actions";

export default defineComponent({
  name: "Withdrawal",
  emits: ["close"],
  setup(props, { emit }) {
    const { t } = useI18n({ useScope: "global" });
    const password = ref("");
    // const error = ref("");
    const store = useStore();

    const alert: Ref<IAlert> = ref({
      type: messageType.ERROR,
      content: "",
    });

    const withdrwal = async () => {
      const res: AxiosResponse<IResponseData<string>> =
        await $API.SignService.withdraw(password.value);
      const { data } = res;
      if (data.Exception === ExceptionMessages.FAILED) {
        console.error(data.Exception);
      } else if (!data.Exception && data.ResultData === ResultDatas.SUCCESS) {
        store.dispatch(AuthActionTypes.SIGNOUT);
      }
    };

    const close = () => {
      const body$ = document.querySelector("body");
      if (body$?.classList.contains("p-overflow-hidden"))
        body$?.classList.remove("p-overflow-hidden");
      emit("close");
    };

    onMounted(() => {
      const body$ = document.querySelector("body");
      if (!body$?.classList.contains("p-overflow-hidden"))
        body$?.classList.add("p-overflow-hidden");
    });

    return {
      t,
      password,
      store,
      alert,
      withdrwal,
      close,
    };
  },
  components: {
    InputText,
    Button,
    Form,
    Field,
  },
});
