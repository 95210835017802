<template>
  <input
    :class="['p-inputtext p-component', { 'p-filled': filled }]"
    :value="modelValue"
    @input="onInput"
    autocapitalize="off"
  />
</template>

<script>
/**
 * 첫 문자 자동 대문자 방지
 */
export default {
  name: "Input Text",
  emits: ["update:modelValue"],
  props: {
    modelValue: null,
  },
  methods: {
    onInput(event) {
      this.$emit("update:modelValue", event.target.value);
    },
  },
  computed: {
    filled() {
      return this.modelValue != null && this.modelValue.toString().length > 0;
    },
  },
};
</script>
